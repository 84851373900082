


































import Vue from 'vue';
import Store from "@/store";
import DiscordLoginProvider from '@/api/DiscordLoginProvider';
import IRedeemedAuthResult from '@/models/auth/IRedeemedAuthResult';
import { Actions } from '@/storemutations';
import IToken from '@/models/util/IToken';
import LoadingErrorable from "@/models/util/LoadingErrorable";
import IDevlinTokenResponse from '@/models/auth/IDevlinTokenResponse';
import AuthProvider from '@/api/AuthProvider';
import RegionProvider, { localRegion } from '@/api/RegionProvider';

interface IData {
    loginStatus: LoadingErrorable<any, any>;
}

export default Vue.extend({
    data(): IData {
        return {
            loginStatus: new LoadingErrorable(),
        };
    },
    components: {
    },
    computed: {
        authenticated(): boolean {
            return this.$store.getters.authenticated;
        },
        authInfo(): IDevlinTokenResponse|null {
            return this.$store.getters.authInfo as IDevlinTokenResponse|null;
        },
        username(): string {
            const authInfo = this.authInfo;
            return (authInfo && authInfo.profile && authInfo.profile.username) || '';
        },
        avatar(): string|null {
            const authInfo = this.authInfo;
            if (!authInfo || !authInfo.profile) {
                return null;
            }

            const avatar = authInfo.profile.avatar;
            const dsid = authInfo.profile.id;

            if (avatar) {
                let ext = "png";
                if (avatar.startsWith("a_")) {
                    ext = "gif";
                }
                return `https://cdn.discordapp.com/avatars/${dsid}/${avatar}.${ext}?size=64`;
            }

            return null;
        }
    },
    methods: {
        async promptLogin() {
            if (this.loginStatus.loading) {
                return;
            }

            this.loginStatus.startLoad();
            this.loginStatus.customStatus = "Waiting for you to log in";
            try {
                const token = await AuthProvider.performDevlinOAuth2Flow();
                this.$store.dispatch(Actions.SetAuthToken, token);
                setTimeout(() => window.location.reload(), 500);
            } catch (error) {
                this.loginStatus.failed(error);
            }
        },
        async logOut() {
            await this.$store.dispatch(Actions.ClearAuthToken);
            
            const region = this.$store.state.regionCode;
            const regionFind = await RegionProvider.getRegionByShortName(region);
            this.$store.dispatch(Actions.SetRegion, regionFind.shortName);
            
            this.loginStatus = new LoadingErrorable();
            if (this.$route.matched.some((r) => r.meta.auth)) {
                this.$router.push({
                    name: "auth",
                    query: {
                        path: this.$route.fullPath,
                    },
                });
            } else {
                setTimeout(() => window.location.reload(), 500);
            }
        },
    }
});
